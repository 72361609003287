<template>
  <div>
    <el-card shadow="never">
      <div slot="header"><b>接口文档</b></div>
      <!--      {{ domain }}/danmu-->

      <!--      https://dm.uazu.cn/danmu?ac=dm&id=11e347e6130ed8fca7f9f2009d5ad181-->

      <!--      https://dm.uazu.cn/danmu?ac=dm&id=11e347e6130ed8fca7f9f2009d5ad181-->


      <div class="wrapper">
        <div class="wrapper-header">
          首次加载弹幕
        </div>
        <div class="wrapper-main">
          <p>请求方式：GET</p>
          <p>请求URL：<code>{{ domain }}/danmu?ac=dm&amp;id=&amp;echo=</code></p>
          <p>请求参数说明：</p>
          <table class="table table-hover table-striped table-bordered table-sm">
            <thead>
            <tr>
              <th scope="col">
                参数名称
              </th>
              <th scope="col">参数类型</th>
              <th scope="col">参数说明</th>
              <th scope="col">参数示例</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>id</td>
              <td>String</td>
              <td>【必填】视频链接MD5或视频链接</td>
              <td>aa40f5828e1629ecdcac3ba8870598fb</td>
            </tr>
            <tr>
              <td>echo</td>
              <td>Integer</td>
              <td>【选填】每秒显示的弹幕数量，整数，如未填写则显示所有弹幕</td>
              <td>1</td>
            </tr>
            </tbody>
          </table>
          <p>例如：{{ domain }}/danmu?ac=dm&amp;id=aa40f5828e1629ecdcac3ba8870598fb&amp;echo=2</p>
          <p>例如：{{ domain }}/danmu?ac=dm&amp;id=https://v.qq.com/x/cover/mzc00200nb1ua89/n41004p2uh7.html&amp;echo=2</p>
        </div>
      </div>

      <div class="wrapper" style="margin-top: 20px;">
        <div class="wrapper-header">
          获取弹幕
        </div>
        <div class="wrapper-main">
          <p>请求方式：GET</p>
          <p>请求URL：<code>{{ domain }}/danmu?ac=get&amp;id=&amp;echo=</code></p>
          <p>请求参数说明：</p>
          <table class="table table-hover table-striped table-bordered table-sm">
            <thead>
            <tr>
              <th scope="col">参数名称</th>
              <th scope="col">参数类型</th>
              <th scope="col">参数说明</th>
              <th scope="col">参数示例</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>id</td>
              <td>String</td>
              <td>【必填】视频链接MD5或视频链接</td>
              <td>aa40f5828e1629ecdcac3ba8870598fb/</td>
            </tr>
            <tr>
              <td>echo</td>
              <td>Integer</td>
              <td>【选填】每秒显示的弹幕数量，整数，如未填写则显示所有弹幕</td>
              <td>1</td>
            </tr>
            </tbody>
          </table>
          <p>例如：{{ domain }}/danmu?ac=get&amp;id=aa40f5828e1629ecdcac3ba8870598fb&amp;echo=2</p>
          <p>例如：{{ domain }}/danmu?ac=get&amp;id=https://v.qq.com/x/cover/mzc00200nb1ua89/n41004p2uh7.html&amp;echo=2</p>
        </div>
      </div>

      <div class="wrapper" style="margin-top: 20px;">
        <div class="wrapper-header">
          发送弹幕
        </div>
        <div class="wrapper-main">
          <p>请求方式：POST</p>
          <p>请求URL：<code>{{ domain }}/danmu</code></p>
          <p>请求参数说明：</p>
          <table class="table table-hover table-striped table-bordered table-sm">
            <thead>
            <tr>
              <th scope="col">参数名称</th>
              <th scope="col">参数类型</th>
              <th scope="col">参数说明</th>
              <th scope="col">参数示例</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>id / player</td>
              <td>String</td>
              <td>【必填】视频链接MD5（id或player二选一）</td>
              <td>aa40f5828e1629ecdcac3ba8870598fb</td>
            </tr>
            <tr>
              <td>text</td>
              <td>String</td>
              <td>【必填】弹幕文本</td>
              <td>这是一条测试的弹幕</td>
            </tr>
            <tr>
              <td>time</td>
              <td>Float</td>
              <td>【必填】弹幕出现时间</td>
              <td>123.358639</td>
            </tr>
            <tr>
              <td>type</td>
              <td>String</td>
              <td>【必填】弹幕位置</td>
              <td>top / right / bottom</td>
            </tr>
            <tr>
              <td>color</td>
              <td>String</td>
              <td>【选填】弹幕颜色，默认值：rgb(255,255,255)</td>
              <td>rgb(255,255,255)</td>
            </tr>
            <tr>
              <td>size</td>
              <td>String</td>
              <td>【选填】弹幕字体大小，默认值：27.5px</td>
              <td>27.5px</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="wrapper" style="margin-top: 20px;">
        <div class="wrapper-header">
          举报弹幕
        </div>
        <div class="wrapper-main">
          <p>请求方式：GET</p>
          <p>请求URL：<code>{{ domain }}/danmu?ac=report&amp;text=&amp;title=&amp;cid=&amp;type=</code></p>
          <p>请求参数说明：</p>
          <table class="table table-hover table-striped table-bordered table-sm">
            <thead>
            <tr>
              <th scope="col">参数名称</th>
              <th scope="col">参数类型</th>
              <th scope="col">参数说明</th>
              <th scope="col">参数示例</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>text</td>
              <td>String</td>
              <td>【必填】投诉的弹幕内容</td>
              <td>这是一条测试的弹幕</td>
            </tr>
            <tr>
              <td>title</td>
              <td>Int</td>
              <td>【必填】投诉ID</td>
              <td>2147483647</td>
            </tr>
            <tr>
              <td>cid</td>
              <td>Int</td>
              <td>【必填】弹幕ID，获取弹幕接口会返回弹幕ID</td>
              <td>16</td>
            </tr>
            <tr>
              <td>type</td>
              <td>String</td>
              <td>【必填】投诉类型</td>
              <td>垃圾广告</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {apiGetDomain} from "@/api/passport";

export default {
  data() {
    return {
      loading: false,
      domain: "",
    };
  },
  methods: {
    getData() {
      this.loading = true;
      apiGetDomain().then((resp) => {
        this.domain = resp.domain;
        this.loading = false;
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;

  &-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    font-weight: 700;
  }

  &-main {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.25rem 1.25rem;
  }
}

code {
  //color: #e83e8c;
  word-wrap: break-word;
  font-weight: 600;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

table {
  border-collapse: collapse;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table-sm td,
.table-sm th {
  padding: .3rem;
}

.table td,
.table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

th {
  text-align: inherit;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>
